<template>
  <div class="sub-header">
    <div class="sub-header__wrap container flex">
      <div class="sub-header__left flex">
        <h1 class="sub-header__title main-title">
          {{ title }}
        </h1>
        <ul v-if="$route.params.type === 'orders'" class="filter__items flex">
          <li class="filter__item">
            <div class="sub-header__btn-block">
              <button
                      class="sub-header__btn"
                      @click="dateShow = !dateShow">
                Выбрать дату
              </button>
              <div v-show="dateShow" class="sub-header__calendar">
                <vc-date-picker
                  v-model="range"
                  class="add__calendar"
                  color="green"
                  :rows="1"
                  is-range
                  @input="inputData()"
                />
                <button class="btn btn-primary mt-2" @click="changeDate">Ok</button>
                <button class="btn btn-secondary mt-2" @click="inputDataClean">Очистить</button>
              </div>

            </div>
          </li>
          <li class="filter__item">
            <button :disabled="this.genButton"
                    class="btn btn-primary ml-5"
                    @click="postData('/web/point-export-date')">
              Сформировать
            </button>
          </li>
        </ul>
        <ul v-if="$route.params.type === 'fuel'" class="filter__items flex">
          <li class="filter__item">
            <div class="sub-header__btn-block">
              <button
                  class="sub-header__btn"
                  @click="dateShow = !dateShow">
                Выбрать дату
              </button>
              <div v-show="dateShow" class="sub-header__calendar">
                <vc-date-picker
                    v-model="range"
                    class="add__calendar"
                    color="green"
                    :rows="1"
                    is-range
                    @input="inputData()"
                />
                <button class="btn btn-primary mt-2" @click="changeDate">Ok</button>
                <button class="btn btn-secondary mt-2" @click="inputDataClean">Очистить</button>
              </div>

            </div>
          </li>
          <li class="filter__item">
            <button :disabled="this.genButton"
                    class="btn btn-primary ml-5"
                    @click="postData('/web/oil-export-date')">
              Сформировать
            </button>
          </li>
        </ul>
        <ul v-if="$route.params.type === 'transport-oil'" class="filter__items flex">
          <li class="filter__item">
            <b-form-select v-model="selected" :options="transports"  value-field="id" text-field="label" class="filter__select m-2" ></b-form-select>
          </li>
          <li class="filter__item">
            <div class="sub-header__btn-block">
              <button
                  class="sub-header__btn"
                  @click="dateShow = !dateShow">
                Выбрать дату
              </button>
              <div v-show="dateShow" class="sub-header__calendar">
                <vc-date-picker
                    v-model="range"
                    class="add__calendar"
                    color="green"
                    :rows="1"
                    is-range
                    @input="inputData()"
                />
                <button class="btn btn-primary mt-2" @click="changeDate">Ok</button>
                <button class="btn btn-secondary mt-2" @click="inputDataClean">Очистить</button>
              </div>

            </div>
          </li>
          <li class="filter__item">
            <button :disabled="this.genButton"
                    class="btn btn-primary ml-5"
                    @click="postData('/web/transport-oil-report')">
              Сформировать
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="dateShow || dateSearchShow"
      class="main-close"
      @click="(dateShow = false), (dateSearchShow = false)"
    />
  </div>
</template>

<script>
// import Cookies from "js-cookie";
import { mapState } from "vuex";

export default {
  data() {
    return {
      title: null,
      dateShow: false,
      dateSearchShow: false,
      range: [],
      genButton: true,
      transports : [],
      selected: null
    };
  },
  computed: {
    ...mapState(["loading", "citiesProducts", "transportList"]),
    list() {
      if (this.citiesProducts) {
        return [
          {
            id: null,
            name: "Выберите",
            disabled: true,
          },
          ...this.citiesProducts,
        ];
      }
      return [
        {
          id: null,
          name: "Выберите",
          disabled: true,
        },
      ];
    },
  },
  watch: {
    "$route.params.type"(to) {
      this.changeRout(to);
    },
  },
  async created() {
    this.$store.dispatch("getCitiesProducts");
    await this.$store.dispatch("getTransportList");

    this.transports.push({id: null, label: 'Выбрать транспорт'});
    this.transportList.forEach(element => {
        if(element.label != '0'){
          this.transports.push(element);
        }
    });
  },
  mounted() {
    this.changeRout(this.$route.params.type);
  },
  methods: {
    inputData() {
      if (this.range) {
        this.range.start = new Date(this.range.start).toISOString().split("T")[0];
        this.range.end = new Date(this.range.end).toISOString().split("T")[0];
        this.$store.commit("dateFilter", this.range);
      }
    },
    postData(url) {
      const formData = new FormData();
      formData.append("from_date", this.range.start);
      formData.append("to_date", this.range.end);
      this.$store.commit("incrementLoading");
      if(this.$route.params.type === 'transport-oil'){
        this.$api
        .get(url, {
          params: {
            from_date: this.range.start,
            to_date: this.range.end,
            transports: this.selected
          },
        })
        .then(() => {
          this.dateShow = false;
          this.$store.dispatch("getTransportFuelReport");
          this.$toast.success("Успешно");
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        })
        .finally(() => {
          this.$store.commit("decrementLoading");
        });
      }else if(this.$route.params.type === 'orders'){
        this.$api
        .get(url, {
          params: {
            from_date: this.range.start,
            to_date: this.range.end
          },
        })
        .then(() => {

          this.dateShow = false;
          this.$toast.success("Отчет формируются! Обновите страницу через 5 минут");
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        })
        .finally(() => {
          this.$store.commit("decrementLoading");
        });
      }else{
        this.$api
        .get(url, {
          params: {
            from_date: this.range.start,
            to_date: this.range.end
          },
        })
        .then(() => {

          this.dateShow = false;
          this.$store.dispatch("getOilExport");
          this.$toast.success("Успешно");
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        })
        .finally(() => {
          this.$store.commit("decrementLoading");
        });
      }

    },
    changeRout(n) {
      switch (n) {
        case "orders":
          this.title = "Отчет о заказах";
          break;
        case "fuel":
          this.title = "Отчет о топливе";
          break;
        case "transport-oil":
          this.title = "Отчет по ГСМ";
          break;
        case "one-c":
          this.title = "Отправки в 1С";
          break;
        case "unsetting-points":
          this.title = "Отложенные заказы";
          break;
        default:
          this.title = "Отчет о заказах";
          break;
      }
    },
    inputDataClean() {
      this.range = []
      this.$store.commit("dateFilter", this.range);
      this.genButton = true;
    },
    changeDate() {
      this.dateShow = false;
      this.genButton = false;
    },
  },
};
</script>

<style scoped>
.sub-header {
  padding: 20px 0;
  background-color: #fff;
  border: 1px solid #f2f2f8;
  margin-bottom: 20px;
}
.sub-header__title {
  margin: 0;
}
.sub-header__btn-block {
  min-width: 140px;
  margin-left: 30px;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 10px;
}
.sub-header__btn {
  height: 40px;
  background: transparent;
  outline: none;
}

.filter__select {
  height: 40px;
  /* background: transparent; */
  border-radius: 10px;
}
.sub-header__calendar {
  position: absolute;
  left: 22%;
  top: 45px;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
.add__calendar {
  position: static;
  background: #fff;
}
.sub-header__item-btn-icon {
  right: 3px;
}
.filter__items {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.filter__item {
  position: relative;
  font-size: 14px;
  margin-top: 10px;
}
</style>
